import './styles.sass'
import {memo, useEffect, useRef, useState} from 'react'
import Modal from '../../../../components/modal/modal'
import {customFetch} from '../../../../services/customFetch'
import TextField from '../../../../components/textfield/textfield'
import Checkbox from '../../../../components/checkbox/checkbox'
import Select from '../../../../components/select/select'
import Button from '../../../../components/button/button'
import {getFP} from '../../../../services/getFingerPrint'

const CreateNewAccountModal = ({
                                   show,
                                   setShow,
                                   reload,
                                   reportIds,
                                   setCurrentPage,
                                   navigateAnimated,
                                   alertMessage,
                                   admins
                               }) => {
    const initData = {
        firstName: null,
        lastName: null,
        email: null,
        reportIds: null,
        isAdmin: false,
        managerId: {},
    }
    const [newAccountData, setNewAccountData] = useState(initData)
    const [username, setUsername] = useState()
    const activeAdmins = admins.filter(ad => ad.isActive)

    let parseAdminObject = {}
    activeAdmins.map((admin) => {
        parseAdminObject[admin.id] = admin.firstName + " " + admin.lastName
    })

    const closeModal = reloadingData => {
        if (reloadingData) reload()
        setShow(false)
        setTimeout(() => {
            setNewAccountData(initData)
            setUsername()
        }, 550)
    }
    const createNewAccount = () => {
        newAccountData.managerId = parseInt(newAccountData.managerId[0])
        customFetch({
            urlEnd: '/panel/create-admin/',
            method: 'POST',
            body: newAccountData,
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: '0baf5wd2-2905-4GR3-450f-86t7ee37dlc8',
            alertMessagesData: {
                loadingMessageText: 'Account is being created!',
                successMessageText: 'Account created successfully',
                errorMessageText: 'Account cannot be created!'
            },
            onLoad: (_data, success) => {
                closeModal(true)
            },
            onCatch: err => {
                console.log(err)
            }
        })
    }

    const emailRegex = RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')

    return (
        <Modal
            className={`create-new-account-modal`}
            headline={'Add new account'}
            show={show}
            submitText={'Create'}
            submitIcon={'check'}
            disabledSubmit={
                !(
                    emailRegex.test(newAccountData.email) ||
                    newAccountData.firstName?.replaceAll(' ', '') ||
                    newAccountData.lastName?.replaceAll(' ', '')
                )
            }
            onSubmit={() => {
                if (
                    emailRegex.test(newAccountData.email) &&
                    newAccountData.firstName?.replaceAll(' ', '') &&
                    newAccountData.lastName?.replaceAll(' ', '')
                )
                    createNewAccount()
            }}
            submitLoading={false}
            cancelText={'Cancel'}
            cancelIcon={'clear'}
            onCancel={() => {
                closeModal()
            }}
        >
            <TextField
                placeholder={'Firstname'}
                value={newAccountData.firstName}
                isInvalid={!newAccountData.firstName?.replaceAll(' ', '')}
                onChange={_value => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        firstName: _value
                    }))
                }}
            />
            <TextField
                placeholder={'Lastname'}
                value={newAccountData.lastName}
                isInvalid={!newAccountData.lastName?.replaceAll(' ', '')}
                onChange={_value => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        lastName: _value
                    }))
                }}
            />
            <TextField
                placeholder={'Email'}
                value={newAccountData.email}
                type="email"
                isInvalid={!emailRegex.test(newAccountData.email)}
                onChange={_value => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        email: _value
                    }))
                }}
            />
            <Select
                options={parseAdminObject ?? {}}
                enableSearch
                selected={[newAccountData.managerId[0]]}
                onSelect={_selectedManager => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        managerId: _selectedManager
                    }))
                }}
            >
                <Button leftIcon={'expand_more'}>
                    Selected Manager: {newAccountData.managerId[1]}
                </Button>
            </Select>
            {!newAccountData.isAdmin && Object.keys(newAccountData.managerId).length !== 0 ? (
                <Select
                    options={reportIds ?? {}}
                    multiSelect
                    enableSearch
                    selected={
                        newAccountData.reportIds
                            ?.split(',')
                            .filter(Boolean)
                            .map(r => [r, '']) ?? []
                    }
                    onSelect={selectedReports => {
                        setNewAccountData(prevNewAccountData => ({
                            ...prevNewAccountData,
                            reportIds: selectedReports
                                .map(r => r[0])
                                .filter(Boolean)
                                .join(',')
                        }))
                    }}
                >

                    <Button leftIcon={'expand_more'}>
                        Selected reports: {newAccountData.reportIds?.split(',').filter(Boolean).length ?? 0}
                    </Button>
                </Select>
            ) : null}
            <Checkbox
                checked={newAccountData.isAdmin}
                onCheck={() => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        isAdmin: !prevNewAccountData.isAdmin
                    }))
                }}
            >
                Admin
            </Checkbox>
        </Modal>
    )
}
export default CreateNewAccountModal
