import {Outlet, Navigate, Route} from 'react-router-dom'

const PrivateRouteAuth = () => {
    const accessToken = localStorage.getItem('accessToken')
    // const accessTokenExpiryTime = localStorage.getItem("accessTokenExpiryTime")
    const powerBiExpiry = localStorage.getItem("reportExpireToken")
    const powerBiTokenStatus = new Date().getTime() >= parseInt(powerBiExpiry, 10)
    // const accessTokenExpiryTimeStatus = new Date().getTime() >= parseInt(accessTokenExpiryTime,10)
    if (powerBiTokenStatus) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('ssoToken')
        localStorage.removeItem('reportExpireToken')
        window.location.href = '/login'
    }
    return accessToken ? <Outlet/> : <Navigate to={'/login'}/>
}

const PrivateRoute = ({exact, path, Component, props, key}) => {
    return (
        <Route key={key} exact={exact} path={path} element={<PrivateRouteAuth/>}>
            <Route exact={exact} path={path} element={<Component {...props} />}/>
        </Route>
    )
}

export default PrivateRoute
