import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import Login from './login/login'
import Start from './start/start'
import SsoLogin from "./login/ssoLogin";
import PrivateRoute from './private-route'
import Page404 from './404/404'
import './pages.sass'
import {useEffect, useRef, useState} from 'react'
import Button from '../components/button/button'
import Select from '../components/select/select'
import {useStore} from '../hooks-store/store'
import Switcher from '../components/switcher/switcher'
import Theme from '../_themes'
import Admin from './admin/admin'
import {customFetch} from '../services/customFetch'
import {models} from 'powerbi-client'
import ChangeMyDataModal from './modals/edit-account/changeMyDataModal'
import Logo from '../components/logo/logo'

const Pages = ({currentOpenPage}) => {
    const [currentPage, setCurrentPage] = useState()
    const [closEffect, setCloseEffect] = useState(false)
    const [user, setUser] = useState()
    const [changeMyData, setChangeMyData] = useState()
    const [currentSelectedTheme, setCurrentSelectedTheme] = useState(localStorage.getItem('selected-theme'))

    useEffect(() => {
        const userFromlocalStorage = localStorage.getItem('userData')
        if (userFromlocalStorage) {
            setUser(JSON.parse(userFromlocalStorage))
        }
    }, [])

    const navigate = useNavigate()

    const dispatch = useStore(true)[1]
    const alertMessage = async ({
                                    message,
                                    status,
                                    dismissible = true,
                                    timeoutInMilliseconds = 888888e8,
                                    id = '_',
                                    MID = undefined,
                                    onClose = undefined
                                }) => {
        const messageProperties = {
            message: message,
            id: id,
            uuid: MID,
            type: status.toUpperCase(), // success, or warning, or danger
            dismissible: dismissible,
            timeoutInMilliseconds: timeoutInMilliseconds,
            onClose: onClose
        }
        dispatch('ADD_NEW_MESSAGE', messageProperties)
    }

    const customersWasLoaded = useRef(false)

    useEffect(() => {
        if (!customersWasLoaded.current && localStorage.getItem('accessToken')) {
            //getAccessType()
            customersWasLoaded.current = true
        }
    }, [currentPage])

    useEffect(() => {
        customFetch({
            urlEnd: '/user/',
            method: 'GET',
            headers: {
                checkTokenExpired: true
            },
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: "_scase4518635",
            onLoad: (_data, success) => {
                // pass
            }
        })
    }, [])

    const [embedConfigs, setEmbedConfigs] = useState()
    const [embedConfigsLoading, setEmbedConfigsLoading] = useState()
    const [embedConfig, setEmbedConfig] = useState()
    const [selectedReport, setSelectedReport] = useState()

    const navigateAnimated = to => {
        setCloseEffect(true)

        setTimeout(() => {
            navigate(to)
            setCloseEffect(false)
        }, 550)
    }

    return (
        <div className={`powerbi-pages ${currentPage}`}>
            {(currentOpenPage !== '/login' && currentOpenPage !== '/sso-login') ? (
                <div className="nav">
                    <div className={'left-side'}>
                        <div
                            className={'logo-container'}
                            onClick={() => {
                                if (currentPage !== 'start') navigateAnimated('/home')
                                setChangeMyData()
                            }}
                        >
                            <Logo/>
                        </div>

                        {currentPage === 'start' ? (
                            <Select
                                options={(() => {
                                    const _reportIds = {}
                                    if (embedConfigs) {
                                        embedConfigs.reportConfig.forEach(_report => {
                                            _reportIds[_report.reportId] = _report['reportName']
                                                .replace('_try', '')
                                                .replaceAll('_', ' ')
                                        })
                                    }

                                    return _reportIds
                                })()}
                                loading={embedConfigsLoading}
                                selected={selectedReport}
                                className={'select-report'}
                                enableSearch
                                onSelect={_selectedReportId => {
                                    if (_selectedReportId && embedConfigs) {
                                        const _foundReport = embedConfigs.reportConfig.filter(_report => {
                                            return _report.reportId === _selectedReportId[0]
                                        })
                                        if (_foundReport.length) {
                                            const reportLoadConfig = {
                                                type: 'report',
                                                tokenType: models.TokenType.Embed
                                            }
                                            reportLoadConfig.accessToken = embedConfigs.accessToken

                                            // You can embed different reports as per your need
                                            reportLoadConfig.embedUrl = _foundReport[0].embedUrl

                                            setEmbedConfig(reportLoadConfig)
                                            setSelectedReport(_selectedReportId)
                                        }
                                    }
                                }}
                            >
                                <Button
                                    outlined
                                    leftIcon={'expand_more'}
                                    onClick={() => {
                                        setChangeMyData()
                                    }}
                                >
                                    <b>Selected Report: </b>
                                    {selectedReport && selectedReport.length ? selectedReport[1] : '---'}
                                </Button>
                            </Select>
                        ) : null}
                    </div>
                    <div className={'right-side'}>
                        {user && user.isAdmin ? (
                            <Button
                                outlined
                                blue
                                leftIcon={'admin_panel_settings'}
                                onClick={() => {
                                    if (currentPage !== '/admin') navigateAnimated('/admin')
                                    setChangeMyData()
                                }}
                            >
                                Admin Panel
                            </Button>
                        ) : null}
                        <Button
                            className={'icon-button manage-accounts'}
                            outlined
                            leftIcon={'manage_accounts'}
                            onClick={() => {
                                if (user) setChangeMyData(user)
                            }}
                        />
                        <Button
                            className={'icon-button manage-accounts'}
                            outlined
                            yellow={currentSelectedTheme === 'dark'}
                            leftIcon={currentSelectedTheme === 'dark' ? 'light_mode' : 'dark_mode'}
                            onClick={() => {
                                Theme.toggleTheme()
                                setCurrentSelectedTheme(localStorage.getItem('selected-theme'))
                                setChangeMyData()
                            }}
                        />
                        <Button
                            className={'icon-button logout'}
                            outlined
                            red
                            leftIcon={'logout'}
                            onClick={() => {
                                setChangeMyData()
                                setEmbedConfigs()
                                setEmbedConfig()
                                localStorage.removeItem('accessToken')
                                localStorage.removeItem('refreshToken')
                                localStorage.removeItem('userData')
                                localStorage.removeItem('ssoToken')
                                localStorage.removeItem('reportExpireToken')
                                navigateAnimated('/login')
                            }}
                        />
                    </div>
                </div>
            ) : null}

            <div className={`powerbi-pages-container${changeMyData ? ' modal-is-open' : ''}`}>
                <div className={`switch-animation${!closEffect ? ' dive-in' : ' dive-out'}`}>
                    <Routes>
                        {PrivateRoute({
                            exact: true,
                            path: '/home',
                            Component: Start,
                            props: {
                                setCurrentPage: setCurrentPage,
                                navigateAnimated: navigateAnimated,
                                alertMessage: alertMessage,
                                embedConfigs: embedConfigs,
                                setEmbedConfigs: setEmbedConfigs,
                                embedConfig: embedConfig,
                                setEmbedConfig: setEmbedConfig,
                                setSelectedReport: setSelectedReport,
                                selectedReport: selectedReport,
                                embedConfigsLoading: embedConfigsLoading,
                                setEmbedConfigsLoading: setEmbedConfigsLoading
                            }
                        })}
                        {PrivateRoute({
                            exact: true,
                            path: '/admin',
                            Component: Admin,
                            props: {
                                setCurrentPage: setCurrentPage,
                                navigateAnimated: navigateAnimated,
                                alertMessage: alertMessage,
                                user: user
                            }
                        })}
                        <Route
                            exact
                            path={'/login'}
                            element={
                                <Login
                                    alertMessage={alertMessage}
                                    navigateAnimated={navigateAnimated}
                                    setCurrentPage={setCurrentPage}
                                    setUser={setUser}
                                />
                            }
                        />
                        <Route
                            exact
                            path={'/sso-login'}
                            element={
                                <SsoLogin
                                    alertMessage={alertMessage}
                                    navigateAnimated={navigateAnimated}
                                    setCurrentPage={setCurrentPage} setUser={setUser}
                                />
                            }
                        />
                    </Routes>
                </div>
            </div>
            <ChangeMyDataModal
                show={changeMyData}
                userData={changeMyData}
                setUserData={setUser}
                setShow={setChangeMyData}
                setCurrentPage={setCurrentPage}
                navigateAnimated={navigateAnimated}
                alertMessage={alertMessage}
                setEmbedConfigs={setEmbedConfigs}
                setEmbedConfig={setEmbedConfig}
            />
        </div>
    )
}

export default Pages
