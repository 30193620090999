import React from "react";
import Modal from "../../../components/modal/modal";
import {customFetch} from "../../../services/customFetch";

export const ConfirmSessionModal = ({
                                        showSessionModal,
                                        setShowSessionModal,
                                        setEmbedConfigs,
                                        setReportTokenExpiry,
                                        navigateAnimated,
                                        alertMessage
                                    }) => {
    const getEmbedInfosMID = "asdf651as5d3af35df15";
    const closeModal = () => {
        setShowSessionModal(false)
    }

    const fetchReportsSession = () => {
        customFetch({
            urlEnd: "/reports/get-reports/",
            method: "GET",
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: getEmbedInfosMID,
            alertMessagesData: {
                loadingMessageText: 'Your session is being extending!',
                successMessageText: 'Session extended successfully',
                errorMessageText: 'Something went wrong!'
            },
            onLoad: (_data, success) => {
                if (_data && success) {
                    const powerBiExpiryTimeStamp = new Date(_data['tokenExpiry']).getTime()
                    localStorage.setItem("reportExpireToken", powerBiExpiryTimeStamp)
                    setReportTokenExpiry(localStorage.getItem("reportExpireToken"))
                    setEmbedConfigs(_data);
                    setShowSessionModal(false)
                }
            },
        });
    }


    return (
        <>
            <Modal
                className={`create-new-account-modal`}
                headline={'Warning'}
                show={showSessionModal}
                submitText={'Continue'}
                submitIcon={'check'}
                onSubmit={fetchReportsSession}
                submitLoading={false}
                cancelText={'Cancel'}
                cancelIcon={'clear'}
                onCancel={closeModal}
            >
                <p>Your session is going to expire in next 10 minutes. Do you want Continue</p>
            </Modal>
        </>
    )
}